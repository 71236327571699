export function INIT_MOBILE_NAV_HANDLER() {
    let mql = window.matchMedia("(min-width: 1200px)");
    const MOBILE_NAV_TOGGLE_INPUT = document.querySelector('#menuToggleCheckbox');
    const BODY_ELEMENT = document.querySelector('body');

    MOBILE_NAV_TOGGLE_INPUT.addEventListener('change', () => {
        if (MOBILE_NAV_TOGGLE_INPUT.checked) BODY_ELEMENT.classList.add('menu--is-active');
        else BODY_ELEMENT.classList.remove('menu--is-active');
    });

    window.addEventListener('resize', () => {
        if (!mql.matches) return;
        MOBILE_NAV_TOGGLE_INPUT.checked = false;
        BODY_ELEMENT.classList.remove('menu--is-active');
    });
}
