import Headroom from 'headroom.js';
import {Popover, ScrollSpy} from "bootstrap";

import {INIT_FANCYBOX} from "./js/components/init-fancybox";
import {INIT_COOKIE_MANAGER} from "./js/ccm/init.ccm";
import {INIT_SCROLL_TOP} from "./js/components/init-scroll-top";
import {INIT_SCROLLSPY_ANCHOR_NAV, INIT_SCROLLSPY_RECRUITING_NAV} from "./js/components/init-scrollspy";
import {INIT_ANCHOR_NAV} from "./js/components/init-anchor-nav";
import {INIT_HEADROOM} from "./js/components/init-headroom";
import {INIT_SEARCH_HEADER} from "./js/components/init-search-header";
import {INIT_POPOVER} from "./js/components/init-popover";
import {INIT_SWIPER} from "./js/components/init-swipers";
import {INIT_SMOOTH_SCROLL} from "./js/components/init-smooth-scroll";
import {INIT_NAV_DROPDOWN} from "./js/components/init-nav-dropdown";
import {INIT_MOBILE_NAV_HANDLER} from "./js/components/init-mobile-nav-handler";


function checkForGlobalMessageMarquee() {
    const HEADER_GLOBAL_MESSAGE_ELEMENT = document.querySelectorAll('.global-message');

    HEADER_GLOBAL_MESSAGE_ELEMENT.forEach(globalMessageElement => {
        const LINK_ELEMENT = globalMessageElement.querySelector('.global-message-link') || false
        const CONTENT_ELEMENT = globalMessageElement.querySelector('.inner:not([aria-hidden])') || false;

        if (!LINK_ELEMENT || !CONTENT_ELEMENT) return;

        const LINK_WIDTH = LINK_ELEMENT.getBoundingClientRect().width - 10;
        const CONTENT_WIDTH = CONTENT_ELEMENT.getBoundingClientRect().width;

        const SPEED = 100;
        const DURATION = CONTENT_WIDTH / SPEED;

        globalMessageElement.style.setProperty('--animation-duration', `${DURATION}s`);

        if (CONTENT_WIDTH > LINK_WIDTH) globalMessageElement.classList.add('is--marquee');
        else globalMessageElement.classList.remove('is--marquee');

    })
}

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.documentElement.classList.replace('no-js', 'js-rules');
        INIT_SCROLLSPY_ANCHOR_NAV(ScrollSpy);
        INIT_SCROLLSPY_RECRUITING_NAV(ScrollSpy);
    }, 90);

    INIT_NAV_DROPDOWN();
    INIT_MOBILE_NAV_HANDLER();

    INIT_FANCYBOX();
    INIT_COOKIE_MANAGER();
    INIT_POPOVER(Popover);
    INIT_SCROLL_TOP();
    INIT_ANCHOR_NAV();
    INIT_SMOOTH_SCROLL();
    INIT_HEADROOM(Headroom);
    INIT_SEARCH_HEADER();
    INIT_SWIPER()

    checkForGlobalMessageMarquee();

});

window.addEventListener('resize', checkForGlobalMessageMarquee);
